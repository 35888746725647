body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3fr 10fr 1fr;
      grid-template-columns: 3fr 10fr 1fr;
}

@media (max-width: 1100px) and (min-aspect-ratio: 7 / 8) {
  .App {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 400px 10fr 1fr;
        grid-template-columns: 400px 10fr 1fr;
    -ms-grid-rows: 15vh auto;
        grid-template-rows: 15vh auto;
  }
}

@media (max-aspect-ratio: 7 / 8) {
  .App {
    display: inline;
    display: initial;
  }
}

.App .portfolio {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/3;
}

@media (max-width: 1100px) and (min-aspect-ratio: 7 / 8) {
  .App .portfolio {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 15vh auto;
        grid-template-rows: 15vh auto;
  }
}

@media (max-width: 500px) {
  .App .portfolio {
    display: flex;
    flex-direction: column-reverse;
  }
}

.App .portfolio .section_container {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/3;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3/4;
  overflow: hidden;
  z-index: 2;
}

@media (max-width: 1100px) and (min-aspect-ratio: 7 / 8) {
  .App .portfolio .section_container {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1/4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    margin: 0px;
    padding: 5% 10%;
    z-index: 2;
  }
}

@media (max-width: 500px) {
  .App .portfolio .section_container {
    background-color: #0098df;
  }
}

@media (max-width: 500px) {
  .App .portfolio .section_container.fixed {
    position: fixed;
    top: 0px;
    width: 100%;
  }
}

.App .portfolio .section_container .section_mount {
  position: fixed;
  top: 0px;
  right: 0px;
  left: calc(100% - 18vh);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media (max-width: 1100px) and (min-aspect-ratio: 7 / 8) {
  .App .portfolio .section_container .section_mount {
    position: static;
    position: initial;
    top: auto;
    top: initial;
    right: auto;
    right: initial;
    left: auto;
    left: initial;
    -webkit-transform: initial;
            transform: none;
            transform: initial;
  }
}

@media (max-aspect-ratio: 7 / 8) {
  .App .portfolio .section_container .section_mount {
    top: 15vh;
  }
}

@media (max-width: 500px) {
  .App .portfolio .section_container .section_mount {
    position: static;
    position: initial;
    top: auto;
    top: initial;
    right: auto;
    right: initial;
    left: auto;
    left: initial;
    -webkit-transform: initial;
            transform: none;
            transform: initial;
  }
}

.App .portfolio .section_container .section_mount .section {
  position: relative;
  opacity: 0.09;
  font-size: 10rem;
}

@media (max-width: 1100px) and (min-aspect-ratio: 7 / 8) {
  .App .portfolio .section_container .section_mount .section {
    position: relative;
    opacity: 0.09;
    font-size: 5rem;
    margin: auto;
  }
}

@media (max-aspect-ratio: 7 / 8) {
  .App .portfolio .section_container .section_mount .section {
    margin: 20px 0px 100px 0px;
    font-size: 4rem;
  }
}

@media (max-width: 500px) {
  .App .portfolio .section_container .section_mount .section {
    margin: 1% 2%;
    font-size: 2rem;
    color: white;
    opacity: 1;
  }
}
/*# sourceMappingURL=App.css.map */
.about {
  position: fixed;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 2fr 5fr;
      grid-template-rows: 2fr 5fr;
  height: 100vh;
  width: 100%;
  min-width: 360px;
  max-width: 400px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

@media (max-aspect-ratio: 7 / 8) {
  .about {
    position: relative;
    max-width: none;
    max-width: initial;
    box-shadow: none;
            box-shadow: initial;
    height: 902px;
  }
}

@media (max-width: 500px) {
  .about {
    height: 902px;
    min-width: 320px;
  }
}

.about .inner_container {
  position: absolute;
}

.about .inner_container .banner_section {
  position: relative;
  display: flex;
  flex-direction: column;
}

.about .inner_container .banner_section .banner {
  width: 100%;
  height: 20vh;
  position: absolute;
  object-fit: cover;
  object-position: center;
  opacity: 0.75;
  z-index: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.about .inner_container .banner_section .headshot {
  max-width: 160px;
  min-width: 130px;
  width: 8vw;
  max-height: 160px;
  min-height: 130px;
  height: 8vw;
  object-fit: cover;
  object-position: top;
  margin: 25% auto 0% 15%;
  border-radius: 50%;
  border: white 5px solid;
  z-index: 1;
}

.about .inner_container .main_section {
  padding: 0% 15% 15% 15%;
}

.about .inner_container .main_section .header .title {
  font-size: 2rem;
  margin: 0px 0px 10px 0px;
}

.about .inner_container .main_section .header .subtitle {
  font-size: 1rem;
  margin: 25px 0px 0px 0px;
}

.about .inner_container .main_section .header .email {
  text-decoration: none;
  font-weight: 700;
  color: #0098df;
}

.about .inner_container .main_section .btn_container {
  margin-top: 10%;
  display: flex;
}

.about .inner_container .main_section .btn_container .btn_noback {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  background-color: #0098df;
  border: none;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease;
  transition: background-color 0.15s ease;
  margin-right: 15px;
}

.about .inner_container .main_section .btn_container .btn_noback:hover {
  background-color: #005279;
}

.about .inner_container .main_section .info_section {
  margin-top: 10%;
}
/*# sourceMappingURL=About.css.map */
.projects {
  padding: 1% 0% 0% 0%;
  margin: 8vh 0vw 35vh 0vw;
}

@media (max-width: 1100px) and (min-aspect-ratio: 7 / 8) {
  .projects {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/3;
    margin: 0px;
  }
}

@media (max-aspect-ratio: 7 / 8) {
  .projects {
    margin: 8vh 0vw 15vh 0vw;
  }
}

.projects .project {
  display: flex;
  flex-direction: row;
  margin: 5vh 10vw;
  padding: 3%;
  background-color: white;
  border-radius: 20px;
  -webkit-transition: all 0.5s ease-out, -webkit-box-shadow 0.75s ease;
  -webkit-transition: all 0.5s ease-out, box-shadow 0.75s ease;
  transition: all 0.5s ease-out, box-shadow 0.75s ease;
}

@media (max-width: 1100px) and (min-aspect-ratio: 7 / 8) {
  .projects .project {
    display: flex;
    flex-direction: column;
    margin: 3vh 5vw 15vh 5vw;
  }
}

@media (max-width: 500px) {
  .projects .project {
    flex-direction: column-reverse;
    margin: 0% 0% 30% 0%;
    padding: 0px;
  }
}

.projects .project:hover {
  box-shadow: 0px 2px 20px -7px rgba(0, 0, 0, 0.75), -20px 0px 40px -40px rgba(0, 0, 0, 0.75), 20px 0px 40px -40px rgba(0, 0, 0, 0.75), 0px 0px 0px 100vw rgba(255, 255, 255, 0.75);
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.15s ease, -webkit-box-shadow 0.5s ease;
  -webkit-transition: all 0.15s ease, box-shadow 0.5s ease;
  transition: all 0.15s ease, box-shadow 0.5s ease;
}

.projects .project .section_one {
  width: 50%;
}

@media (max-width: 1100px) and (min-aspect-ratio: 7 / 8) {
  .projects .project .section_one {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .projects .project .section_one {
    width: 100%;
  }
}

.projects .project .section_one .img_container {
  display: flex;
  position: relative;
  height: 100%;
}

@media (max-aspect-ratio: 7 / 8) {
  .projects .project .section_one .img_container {
    display: inline;
    display: initial;
    height: auto;
    height: initial;
  }
}

.projects .project .section_one .img_container:hover .button_container {
  opacity: 1;
}

.projects .project .section_one .app_image {
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 60px 60px -60px rgba(0, 0, 0, 0.5);
  -webkit-transition: -webkit-box-shadow 0.5s ease-out;
  -webkit-transition: box-shadow 0.5s ease-out;
  transition: box-shadow 0.5s ease-out;
}

@media (max-width: 500px) {
  .projects .project .section_one .app_image {
    box-shadow: none;
  }
}

.projects .project .section_one .app_image.true {
  box-shadow: 0px 60px 60px -60px rgba(0, 0, 0, 0);
}

.projects .project .section_one .button_container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  opacity: 0;
  -webkit-transition: opacity 0.15s ease-out;
  transition: opacity 0.15s ease-out;
}

@media (max-aspect-ratio: 7 / 8) {
  .projects .project .section_one .button_container {
    opacity: 1;
    top: calc(100% + 20px);
    height: 40px;
    padding: 0%;
    margin-top: 5%;
    flex-direction: row;
    background: none;
  }
}

@media (max-width: 500px) {
  .projects .project .section_one .button_container {
    opacity: 1;
    top: calc(100% + 20px);
    height: 40px;
    padding: 0%;
    flex-direction: row;
    background: none;
  }
}

.projects .project .section_one .button_container .btn_noback {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  margin: 15% auto 0px auto;
  background: none;
  border: white 2px solid;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease;
  transition: background-color 0.15s ease;
}

@media (max-aspect-ratio: 7 / 8) {
  .projects .project .section_one .button_container .btn_noback {
    margin: auto;
    border: none;
    background: #0098df;
  }
}

@media (max-width: 500px) {
  .projects .project .section_one .button_container .btn_noback {
    margin: 0% auto 0% 15%;
    border: none;
    background: #0098df;
  }
}

.projects .project .section_one .button_container .btn_noback:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.projects .project .section_one .button_container .btn_bottom {
  margin: 10% auto 0px auto;
}

@media (max-aspect-ratio: 7 / 8) {
  .projects .project .section_one .button_container .btn_bottom {
    margin: auto;
  }
}

@media (max-width: 500px) {
  .projects .project .section_one .button_container .btn_bottom {
    margin: 0% 15% 0% auto;
  }
}

.projects .project .section_one .tech_used_medium {
  display: none;
}

@media (max-aspect-ratio: 7 / 8) {
  .projects .project .section_one .tech_used_medium {
    -webkit-font-kerning: none;
            font-feature-settings: "kern" off;
            font-kerning: none;
  }
}

@media (max-width: 500px) {
  .projects .project .section_one .tech_used_medium {
    display: none;
  }
}

.projects .project .section_two {
  width: 50%;
  margin: 0% 0% 0% 5%;
}

@media (max-width: 1100px) and (min-aspect-ratio: 7 / 8) {
  .projects .project .section_two {
    width: 100%;
    margin: 0px;
  }
}

@media (max-width: 500px) {
  .projects .project .section_two {
    width: 100%;
    margin: 0% 0% 10% 0%;
    padding: 0% 15%;
    box-sizing: border-box;
  }
}

.projects .project .section_two .app_name, .projects .project .section_two .app_description {
  margin: 0%;
}

.projects .project .section_two .tech_used {
  display: inline;
  display: initial;
}

@media (max-aspect-ratio: 7 / 8) {
  .projects .project .section_two .tech_used {
    display: block;
  }
}

@media (max-width: 500px) {
  .projects .project .section_two .tech_used {
    display: inline;
    display: initial;
  }
}
/*# sourceMappingURL=Projects.css.map */
